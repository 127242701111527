import React, { useRef, useState } from "react";
import { connect } from "react-redux";

import { JitsiMeeting } from "@jitsi/react-sdk";
import { Button, Box, Typography, SvgIcon } from "@mui/material";
import { Autorenew } from "@mui/icons-material";
import { UUID } from "uuidjs";

import {
    registWebRTC,
    registParticipantId,
    moverStatus,
    dataNotify,
    switchCamera,
} from "../action";
import config from "../../../config";
import store from "../../../store";

const WebRTCView = ({
    isOpenDataChannel,
    remoteNotify,
    remoteEmergency,
    participantId,
    autoNotify,
}) => {
    const viewRef = useRef();
    const [moverId, setMoverId] = useState("");
    const [userId, setUserId] = useState("");

    const handleJaaSIFrameRef = (iframeRef) => {
        iframeRef.style.background = config.webrtc.display.background;
        iframeRef.style.height = config.webrtc.display.height;
    };

    /** Event when API becomes available */
    const handleApiReady = (apiObj) => {
        viewRef.current = apiObj;

        // Event when data is received
        viewRef.current.on("endpointTextMessageReceived", (received) => {
            // console.log(
            //     "** endpointTextMessageReceived:" + JSON.stringify(received)
            // );

            try {
                const remoteNotify = JSON.parse(received.data.eventData.text);
                store.dispatch(dataNotify(remoteNotify));

                received.data.eventData.text = remoteNotify;
                console.log(
                    "endpointTextMessageReceived:" + JSON.stringify(received)
                );
            } catch (err) {
                console.log("***:" + err);
            }
        });

        // Hide Right FilmStrip
        viewRef.current.executeCommand("toggleFilmStrip");

        // event when a user joins
        viewRef.current.on("participantJoined", (user) => {
            // Find your own ParticipantiD
            viewRef.current.getRoomsInfo().then((info) => {
                info.rooms.forEach((room) => {
                    room.participants.forEach((participant) => {
                        if (participant.displayName === userId) {
                            store.dispatch(registParticipantId(participant.id));
                        }
                    });
                });
            });

            // If the joined user name is the MobileMover display name
            if (user.displayName === config.webrtc.moverName) {
                // PIN this user and lock the camera
                viewRef.current.executeCommand("pinParticipant", user.id);
                //apiRef.current.executeCommand('setLargeVideoParticipant', user.id, 'camera');

                viewRef.moverId = user.id;
                setMoverId(user.id);

                const status = store.getState().controller.moverStatus;
                status.id = user.id;
                store.dispatch(moverStatus(status));
            }
        });

        // event when a user left
        viewRef.current.on("participantLeft", (user) => {
            if (user.id === viewRef.moverId) {
                viewRef.moverId = "";
                setMoverId("");

                clearInterval(viewRef.interval);

                const status = store.getState().controller.moverStatus;
                status.id = "";
                store.dispatch(moverStatus(status));
            }
        });

        // Event when data channel is possible
        viewRef.current.on("dataChannelOpened", () => {
            const status = store.getState().controller.moverStatus;
            status.isOpenDataChannel = true;
            store.dispatch(moverStatus(status));

            // Send data periodically if ping is enabled
            if (config.webrtc.ping.enable) {
                if (viewRef.interval) {
                    clearInterval(viewRef.interval);
                }

                viewRef.interval = setInterval(() => {
                    if (viewRef.moverId) {
                        const time = new Date();
                        const ping = { ping: true, time: time.getTime() };
                        viewRef.current.executeCommand(
                            "sendEndpointTextMessage",
                            viewRef.moverId,
                            JSON.stringify(ping)
                        );
                    }
                }, config.webrtc.ping.interval);
            }
        });

        store.dispatch(registWebRTC(apiObj));
    };

    const renderSpinner = () => (
        <div
            style={{
                fontFamily: "sans-serif",
                textAlign: "center",
            }}
        >
            Loading..
        </div>
    );

    const noSignalLayout = () => (
        <Box
            sx={{
                background: "#000000",
                position: "absolute",
                height: config.webrtc.display.height,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
            }}
        >
            <Typography color="#FFFFFF" sx={{ fontSize: 32 }}>
                NO SIGNAL
            </Typography>
        </Box>
    );

    const onSwitchCamera = (event) => {
        store.dispatch(switchCamera());
    };

    // create a unique name
    let id = userId;
    if (id === "") {
        id = UUID.generate();
        setUserId(id);
    }

    const disabled =
        viewRef.moverId === "" ||
        !isOpenDataChannel ||
        participantId === "" ||
        autoNotify.drive_start ||
        remoteNotify.participantId !== "";

    return (
        <Box sx={{ position: "relative" }}>
            {moverId === "" && noSignalLayout()}
            {moverId !== "" && config.webrtc.useBackCamera && (
                <Button
                    style={{
                        position: "absolute",
                        top: "0%",
                        right: "0%",
                        margin: "10px",
                    }}
                    variant="contained"
                    onClick={onSwitchCamera}
                    disabled={disabled}
                >
                    <SvgIcon style={{ fontSize: 40 }} component={Autorenew} />
                </Button>
            )}
            <JitsiMeeting
                domain={config.webrtc.domain}
                roomName={config.webrtc.roomName}
                spinner={renderSpinner}
                userInfo={{ ...config.webrtc.userInfo, displayName: id }}
                configOverwrite={config.webrtc.configOverwrite}
                onApiReady={(externalApi) => handleApiReady(externalApi)}
                getIFrameRef={handleJaaSIFrameRef}
            />
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        moverNotify: state.controller.moverNotify,
        isOpenDataChannel: state.controller.isOpenDataChannel,
        remoteNotify: state.controller.remoteNotify,
        remoteEmergency: state.controller.remoteEmergency,
        participantId: state.controller.participantId,
        autoNotify: state.controller.autoNotify,
    };
};

export default connect(mapStateToProps)(WebRTCView);
