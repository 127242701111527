import ControllerActionTypes from "./actionType";

const DEFAULT_STATE = {
    webrtcObj: null,
    participantId: "",
    remoteEmergency: false,
    isOpenDataChannel: false,
    pingTime: 0,
    moverStatus: {
        id: "",
    },
    remoteNotify: {
        x: 0,
        y: 0,
        participantId: "",
    },
    autoNotify: {
        points: [],
        drive_start: false,
    },
    moverNotify: {
        x: 0,
        y: 0,
        rotate: 0,
        obstacleData: {
            sensor1: [],
            sensor2: [],
            sensor3: [],
            sensor4: [],
            sensor5: [],
            sensor6: [],
            sensor7: [],
            sensor8: [],
        },
    },
    autoStatus: {
        severity: "success",
        open: false,
        msg: "",
    }
};

const controllerReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case ControllerActionTypes.REGIST_WEBRTC:
            return {
                ...state,
                webrtcObj: action.webrtcObj,
            };

        case ControllerActionTypes.REGIST_PARTICIPANTID:
            return {
                ...state,
                participantId: action.id,
            };
        case ControllerActionTypes.AUTO_CONTROL:
            return {
                ...state,
                autoNotify: {
                    ...state.autoNotify,
                    drive_start: action.autoControl,
                },
            };

        case ControllerActionTypes.SET_MAPPOINTS:
            return {
                ...state,
                autoNotify: {
                    ...state.autoNotify,
                    points: action.mapPoints,
                },
            };
        case ControllerActionTypes.REMOTE_EMERGENCY:
            return {
                ...state,
                remoteEmergency: action.remoteEmergency,
                autoNotify: {
                    ...state.autoNotify,
                    drive_start: false,
                },
            };

        case ControllerActionTypes.MOVER_STATUS:
            return {
                ...state,
                moverStatus: {
                    id: action.moverStatus.id,
                },
                isOpenDataChannel: action.moverStatus.isOpenDataChannel
                    ? action.moverStatus.isOpenDataChannel
                    : state.isOpenDataChannel,
            };

        case ControllerActionTypes.DATA_NOTIFY:
            let notify = { ...state };
            // Data communication from MobileMover
            if (action.notify.moverNotify) {
                let obstacleData = notify.moverNotify.obstacleData;

                let x = action.notify.moverNotify.x;
                let y = action.notify.moverNotify.y;
                let rotate = action.notify.moverNotify.rotate;

                const sensorNameKey = Object.keys(
                    action.notify.moverNotify.sensor_data
                );

                for (const sensorName of sensorNameKey) {
                    const sensor_data = {
                        sensor_status:
                            action.notify.moverNotify.sensor_data[sensorName]
                                .sensor_status,
                        range: action.notify.moverNotify.sensor_data[sensorName]
                            .range,
                    };

                    if (obstacleData[sensorName].length < 5) {
                        obstacleData[sensorName].push(sensor_data);
                    } else {
                        obstacleData[sensorName].shift();
                        obstacleData[sensorName].push(sensor_data);
                    }
                }

                //console.log(action.notify.moverNotify.sensor_data);

                notify = {
                    ...notify,
                    moverNotify: {
                        x: x,
                        y: y,
                        rotate: rotate,
                        obstacleData: obstacleData,
                    },
                    remoteEmergency: action.notify.moverNotify.emergency,
                    autoNotify: {
                        ...notify.autoNotify,
                        drive_start: action.notify.moverNotify.drive_start,
                    },
                };
            }

            // Data communication from webapps
            if (action.notify.remoteNotify) {
                notify = {
                    ...notify,
                    remoteNotify: {
                        x: action.notify.remoteNotify.x,
                        y: action.notify.remoteNotify.y,
                        participantId: action.notify.remoteNotify.participantId,
                    },
                };
            }

            if (action.notify.autoNotify) {
                notify = {
                    ...notify,
                    autoNotify: {
                        ...notify.autoNotify,
                        drive_start: action.notify.autoNotify.drive_start,
                    },
                };
            }

            if (action.notify.pong) {
                const time = new Date();

                // Since it is a round trip, half of the time is used for Ping.
                const pingTime = (time.getTime() - action.notify.time) / 2;
                if (pingTime) {
                    notify = {
                        ...notify,
                        pingTime: pingTime,
                    };
                }
            }

            if (action.notify.remoteEmergency !== undefined) {
                notify = {
                    ...notify,
                    remoteEmergency: action.notify.remoteEmergency,
                };
            }

            if (action.notify.autoStatus && notify.autoNotify.drive_start) {
                //"auto_status": {"status": "default", "points": 1, "checkpoint": 1}

                let isOpen = false;
                let severity = "success";
                let msg = "";
                switch (action.notify.autoStatus.status) {
                    case "error":
                    case "failed":
                        isOpen = true;
                        severity = "error";
                        msg = "自律走行に失敗しました";
                        break;
                    case "cancel":
                        isOpen = true;
                        severity = "info";
                        msg = "自律走行はキャンセルされました";
                        break;
                    case "goal":
                        if (action.notify.autoStatus.points === action.notify.autoStatus.checkpoint) {
                            isOpen = true;
                            msg = "自律走行は目的に到着しました。";
                        }
                }

                if (isOpen) {
                    notify = {
                        ...notify,
                        autoStatus: {
                            open: isOpen,
                            severity: severity,
                            msg: msg
                        },
                        autoNotify: {
                            ...notify.autoNotify,
                            drive_start: !isOpen,
                        },
                    }
                }
            }

            return notify;


        case ControllerActionTypes.ALERT_CLOSE:
            return {
                ...state,
                autoStatus: {
                    ...state.autoStatus,
                    open: false,
                },
            };
        default:
            return state;
    }
};

export default controllerReducer;
