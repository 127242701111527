/**
 * Various setting information
 */
const config = {
    auth: {
        /** osaka-u Login verification API */
        api_url: "https://ou.ui-api.jp/api/v1/valid_token/",

        /** Login URL */
        login_url: "https://ou.ui-api.jp/login/",

        /** enable login */
        enabled: false,
    },
    webrtc: {
        /** WebRTC server URL */
        domain: "meet.osaka-u-robot.net",
        /** Meeting name (Match with MobileMover side) */
        roomName: "Mover01",

        /** */
        userInfo: {},

        /** MobileMover participant name (Match with MobileMover side) */
        moverName: "Mover01",

        /** See here for settings */
        /** https://github.com/jitsi/jitsi-meet/blob/master/config.js */
        configOverwrite: {
            subject: "MOVER", // Subtitle (Not necessary)

            startWithAudioMuted: true, // mute audio
            startWithVideoMuted: true, // mute video
            startScreenSharing: false, // Disable screen sharing
            hideConferenceTimer: true, // Hide timer
            hideConferenceSubject: true, // Hide meeting name
            hideParticipantsStats: false, // Hide statistics
            hideDisplayName: true, // Hide name
            disableModeratorIndicator: true,

            disableAudioLevels: true,

            disableLocalVideoFlip: true,

            enableInsecureRoomNameWarning: false, // Hide warning

            toolbarButtons: [], // Hide Toolbar

            enableLobbyChat: false, // Disable chat
            disableIncomingMessageSound: true, // Disable chat sound effects

            notifications: [], // Disable audio when chatting
            hideDominantSpeakerBadge: false, // Hide speaker batches

            disableTileView: true, // Disable tile view
            hideLogo: true, // ロゴの非表示（なぜか有効にならない・・）
            disableSelfView: true, // Disable selfview
            disableSelfViewSettings: true, // Disabling selfview settings

            remoteVideoMenu: {
                // Disabling setting changes for participants
                disabled: true,
            },

            disableFilmstripAutohiding: true,
            filmstrip: {
                disableResizable: true, // Disable filmstrip resizing
                disableStageFilmstrip: true,
            },

            prejoinPageEnabled: false, // Hide prejoin screen
            prejoinConfig: {
                enabled: false,
            },

            deeplinking: {
                disabled: true, // Disable the app recommendation screen on mobile devices
                hideLogo: true,
            },
        },

        ping: {
            enable: true,
            interval: 3000,
        },

        /** video view size */
        display: {
            height: "280px",
            background: "#3d3d3d",
        },

        useBackCamera: true,
    },

    /** MobileMover control settings */
    mover: {
        speed: {
            max: 100, // Maximum moving percent
            min: 10, // Minimum moving percent
            default: 50, // Initial movement percent
            unit: "%", // unit
            value: 55, // Speed (cm/sec)
        },

        rotation: {
            max: 100, // Maximum moving percent
            min: 10, // Minimum moving percent
            default: 50, // Initial movement percent
            unit: "%", // unit
            value: 68.755, // Rotation angle (degree)
        },
    },

    map: {
        zoom: {
            max: 10,
            min: -10,
        },
    },
};

export default config;
