import AuthActionTypes from "./actionType";
import { authValidation } from "./function";
import { signIn, signOut, redirectLogin } from "./action";
import config from "../../config";

const authMiddleWare = (store) => (next) => (action) => {
    const { dispatch } = store;

    // eslint-disable-next-line default-case
    switch (action.type) {
        case AuthActionTypes.AUTH:
            authValidation(action.token)
                .then(() => {
                    // If the approval is successful, go to the operation screen
                    dispatch(signIn(action.token));
                })
                .catch(() => {
                    // If authorization fails, go to the login screen
                    dispatch(signOut());
                });
            break;

        case AuthActionTypes.SIGNIN:
            window.location.replace("/");
            break;

        case AuthActionTypes.SIGNOUT:
            dispatch(redirectLogin());
            break;

        case AuthActionTypes.REDIRECT_LOGIN:
            window.location.replace(config.auth.login_url);
            break;
    }

    return next(action);
};

export default authMiddleWare;
