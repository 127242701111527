import { Routes, Route } from "react-router-dom";
import "./App.css";
import Controller from "./features/controller";
import Auth from "./features/auth";

const App = () => {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Controller />} />
                <Route path="/callback" element={<Auth />} />
            </Routes>
        </div>
    );
};

export default App;
