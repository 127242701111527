/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";

import "../style/obstacleInfo.css";

const ObstacleInfo = ({ hidden, moverNotify }) => {
    const obstacleInfoKey = Object.keys(moverNotify.obstacleData);

    let moverStateDataList = obstacleInfoKey
        ? obstacleInfoKey.map((data) => {
              let statusImageName = "";

              const datalist = moverNotify.obstacleData[data];

              let sensor_status_list = [];

              for (const sensorData of datalist) {
                  sensor_status_list.push(sensorData.sensor_status);
              }

              //   配列の値の最大値を取得する
              const sensor_status_max = String(Math.max(...sensor_status_list));

              if (sensor_status_max === "2") {
                  // 危険
                  statusImageName = "danger-point.png";
              } else if (sensor_status_max === "1") {
                  // 注意
                  statusImageName = "warning-point.png";
              } else if (sensor_status_max === "0") {
                  // 安全
                  statusImageName = "safety-point.png";
              }

              return {
                  ...moverNotify.obstacleData[data],
                  imageName: statusImageName,
              };
          })
        : [];

    return (
        <Box hidden={hidden} className="box">
            <div class="imageContainer">
                <img className="mover" src={"moverInfo.png"} />
                {moverStateDataList.map((data, index) => (
                    <img
                        key={index}
                        className={`moverState${index + 1}`}
                        src={data.imageName}
                    />
                ))}
            </div>
        </Box>
    );
};

const ObstacleInfoProps = (state) => {
    return {
        moverNotify: state.controller.moverNotify,
    };
};

export default connect(ObstacleInfoProps)(ObstacleInfo);
