import AuthActionTypes from './actionType'

const DEFAULT_STATE = {
    token: '',
};

const authReducer = (state = DEFAULT_STATE, action) => {

  switch (action.type) {    
    case AuthActionTypes.SIGNIN:
        return {
          ...state,
          token: action.token,
        }

    case AuthActionTypes.SIGNOUT:
        return {
          ...state,
          token: '',
        }

    default:
      return state
  }
}

export default authReducer