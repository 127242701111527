import React, { useState } from "react";

import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";

const Pad = ({ id = 0, disabled, icon, onPadDown, onPadUp }) => {
    const [state, setState] = useState(0);

    const onMouseDown = () => {
        if (state === 1) {
            return;
        }

        setState(1);

        if (onPadDown) {
            onPadDown(id);
        }
    };

    const onMouseUp = () => {
        if (state === 0) {
            return;
        }

        setState(0);

        if (onPadUp) {
            onPadUp(id);
        }
    };

    return (
        <Button
            disabled={disabled}
            variant="contained"
            color="primary"
            style={{ padding: "6px" }}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseUp}
            onTouchStart={onMouseDown}
            onTouchEnd={onMouseUp}
            onTouchCancel={onMouseUp}
        >
            <SvgIcon style={{ fontSize: 30 }} component={icon} />
        </Button>
    );
};

export default Pad;
