import AuthActionTypes from "./actionType";

export const authentication = (token) => {
    return {
        type: AuthActionTypes.AUTH,
        token,
    };
};

export const signIn = (token) => {
    return {
        type: AuthActionTypes.SIGNIN,
        token,
    };
};

export const signOut = () => {
    return {
        type: AuthActionTypes.SIGNOUT,
    };
};

export const redirectLogin = () => {
    return {
        type: AuthActionTypes.REDIRECT_LOGIN,
    };
};
