import ControllerActionTypes from "./actionType";

export const registWebRTC = (webrtcObj) => {
    return {
        type: ControllerActionTypes.REGIST_WEBRTC,
        webrtcObj,
    };
};

export const registParticipantId = (id) => {
    return {
        type: ControllerActionTypes.REGIST_PARTICIPANTID,
        id,
    };
};

export const remoteControl = (remoteControl) => {
    return {
        type: ControllerActionTypes.REMOTE_CONTROL,
        remoteControl,
    };
};

export const autoControl = (autoControl) => {
    return {
        type: ControllerActionTypes.AUTO_CONTROL,
        autoControl,
    };
};

export const setMapPoints = (mapPoints) => {
    return {
        type: ControllerActionTypes.SET_MAPPOINTS,
        mapPoints,
    };
};

export const remoteEmergency = (remoteEmergency) => {
    return {
        type: ControllerActionTypes.REMOTE_EMERGENCY,
        remoteEmergency,
    };
};

export const moverStatus = (moverStatus) => {
    return {
        type: ControllerActionTypes.MOVER_STATUS,
        moverStatus,
    };
};

export const dataNotify = (notify) => {
    return {
        type: ControllerActionTypes.DATA_NOTIFY,
        notify,
    };
};

export const switchCamera = () => {
    return {
        type: ControllerActionTypes.SWITCH_CAMWRA,
    };
};

export const alertClose = () => {
    return {
        type: ControllerActionTypes.ALERT_CLOSE,
    };
}
