import * as React from 'react';
import { useSearchParams } from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { authentication, signOut } from './action';
import store from '../../store';


export const Auth = () => {

    const [searchParams] = useSearchParams();
    if(searchParams.get('token')){

        const token = searchParams.get('token');
        store.dispatch(authentication(token));
    }
    else{
        store.dispatch(signOut());
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Typography>Authentication</Typography>
        </Box>
    );
};

export default Auth;