import config from "../../config";

/**
 * validate token
 * @param {*} token
 */
export function authValidation(token) {
    return new Promise((resolve, rejected) => {
        fetch(config.auth.api_url, {
            headers: {
                Authorization: token,
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve();
                } else {
                    rejected();
                }
            })
            .catch(() => {
                rejected();
            });
    });
}
