/* eslint-disable jsx-a11y/alt-text */
import React, {
    useRef,
    forwardRef,
    useImperativeHandle,
    useState,
    useEffect,
} from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import config from "../../../config";
import { setMapPoints } from "../action";
import store from "../../../store";
import { ConvertToHTMLColor } from "../functions.js";

const MapView = forwardRef((props, ref) => {
    //let targetArray = []

    let markerX;
    let markerY;

    const mapScale = props.mapScale ? props.mapScale : 1.0;
    const disabled = props.disabled !== undefined ? props.disabled : false;
    const moverWidth = props.moverWidth;
    const yaml =
        props.yaml !== undefined
            ? props.yaml
            : { resolution: 1, origin: [0, 0, 0] };
    const enablePinColor =
        props.enablePinColor !== undefined ? props.enablePinColor : undefined;
    //let zoomRate = mapScale

    let moverX = (props.moverPosition.x - yaml.origin[0]) / yaml.resolution;
    let moverY = (props.moverPosition.y - yaml.origin[1]) / yaml.resolution;
    let moverD = props.moverPosition.rotate;

    /**
     * 初期化処理
     * マップのオリジナルサイズを取得する
     * Moverを設置する
     */
    const init = () => {
        let mapElement = document.querySelector("img#automap");
        var canvas = document.getElementById("mapCanvas");
        var image = document.getElementById("automap");

        canvas.width = image.width;
        canvas.height = image.height;

        if (canvas.getContext) {
            let context = canvas.getContext("2d");

            context.clearRect(0, 0, image.width, image.height);

            var x = (canvas.width - image.width) / 2;
            var y = (canvas.height - image.height) / 2;
            context.drawImage(image, x, y);
            setMapContext(context);
        }

        let size = {
            ...mapSize,
            width: mapElement.width,
            height: mapElement.height,
            mapHeight: mapElement.height * mapSize.zoomRate,

        };
        setMapSize(size);

        mapElement.style.width = size.width * mapSize.zoomRate + "px";

        addMover(size);
        mouseDragScrollable("#imageContainer");
    };

    /**
     * 地図の縮尺を変更する
     * ズームのスライダーが動かされた場合に呼ばれる
     * @param e
     */
    const zoomMap = (e) => {
        const maxZoom = config.map.zoom.max;
        const zoom = e.target.value;
        if (zoom >= 0) {
            mapSize.zoomRate = ((maxZoom + zoom) / maxZoom) * mapScale;
        } else {
            mapSize.zoomRate = (maxZoom / (maxZoom + zoom * -1)) * mapScale;
        }

        let mapElement = document.querySelector("img#automap");
        mapElement.style.width = mapSize.width * mapSize.zoomRate + "px";

        mapSize.mapHeight = mapElement.height;
        setMapSize(mapSize);

        addMover(mapSize);
        addMarker(true);
    };

    /**
     * マウスクリックされた位置を取得する
     * マーカー設置のメソッドが呼ばれる
     * @param e
     */
    const getPosition = (e) => {
        if (disabled) {
            return;
        }

        const x = parseInt(e.nativeEvent.offsetX / mapSize.zoomRate);
        const y = parseInt(e.nativeEvent.offsetY / mapSize.zoomRate);
        var imagedata = mapContext.getImageData(x, y, 1, 1);

        //  RGBAの取得
        var r = imagedata.data[0];
        var g = imagedata.data[1];
        var b = imagedata.data[2];
        var a = imagedata.data[3];

        if (enablePinColor && ConvertToHTMLColor(r, g, b) === enablePinColor) {
            const targetX = parseInt(e.nativeEvent.offsetX / mapSize.zoomRate);
            const targetY = parseInt(
                (mapSize.mapHeight - e.nativeEvent.offsetY) / mapSize.zoomRate
            );

            markerX = e.nativeEvent.offsetX;
            markerY = e.nativeEvent.offsetY;
            mapSize.targetArray.push({ x: targetX, y: targetY });

            setMapSize(mapSize);

            let points = [];
            mapSize.targetArray.forEach((point, index) => {
                points.push({
                    x: point.x * yaml.resolution + yaml.origin[0],
                    y: point.y * yaml.resolution + yaml.origin[1],
                    time:
                        store.getState().controller.autoNotify.points.length !==
                            0 &&
                            store.getState().controller.autoNotify.points[index]
                            ? store.getState().controller.autoNotify.points[
                                index
                            ].time
                            : 0,
                });
            });

            store.dispatch(setMapPoints(points));
            addMarker();
        }
    };

    /**
     * マーカーをすべて削除する
     */
    const removeAllMarkers = () => {
        const oldMarkerElements = document.querySelectorAll(".target-marker");
        oldMarkerElements.forEach((oldMarkerElement) => {
            oldMarkerElement.remove();
        });
    };

    /**
     * マーカーを設置する
     * 縮尺変更時に呼ばれた場合は縮尺を計算して設置する
     * @param isZoom
     */
    const addMarker = (isZoom = false) => {
        const oldMarkerElements = document.querySelectorAll(".target-marker");
        if (isZoom && oldMarkerElements.length === 0) {
            return;
        }
        if (isZoom) {
            removeAllMarkers();
        }
        let imageContainerElement = document.querySelector("#imageContainer");
        let markerWidth = 50;

        if (isZoom) {
            mapSize.targetArray.forEach((target, index) => {
                let markerElement = document.createElement("img");
                markerElement.title = `ポイント${index + 1}`;
                markerElement.src = "marker.png";
                markerElement.className = "absolute target-marker";
                markerElement.style.width = markerWidth + "px";
                markerElement.style.left =
                    target.x * mapSize.zoomRate - markerWidth / 2 + "px";
                markerElement.style.top =
                    mapSize.mapHeight -
                    target.y * mapSize.zoomRate -
                    markerWidth +
                    "px";
                imageContainerElement.appendChild(markerElement);
            });
        } else {
            const pointNumber = mapSize.targetArray.length;
            let markerElement = document.createElement("img");
            markerElement.title = `ポイント${pointNumber}`;
            markerElement.src = "marker.png";
            markerElement.className = "absolute target-marker";
            markerElement.style.width = markerWidth + "px";
            markerElement.style.left = markerX - markerWidth / 2 + "px";
            markerElement.style.top = markerY - markerWidth + "px";
            imageContainerElement.appendChild(markerElement);
        }
    };

    /**
     * Moverを設置する
     */
    const addMover = (size) => {
        const oldMoverElement = document.querySelector("#mover");
        if (oldMoverElement) {
            oldMoverElement.remove();
        }
        let imageContainerElement = document.querySelector("#imageContainer");
        if (!imageContainerElement) {
            return;
        }

        let moverElement = document.createElement("img");
        moverElement.src = "mover.png";
        moverElement.title = "mover";
        moverElement.className = "absolute";
        moverElement.id = "mover";
        moverElement.style.width = moverWidth * mapSize.zoomRate + "px";
        moverElement.style.left =
            moverX * mapSize.zoomRate -
            (moverWidth * mapSize.zoomRate) / 2 +
            "px";

        moverElement.style.top =
            (size.height - moverY) * mapSize.zoomRate -
            (moverWidth * mapSize.zoomRate) / 2 +
            "px";

        moverElement.style.transform = "rotate(" + (360 - moverD + 90) + "deg)";
        imageContainerElement.appendChild(moverElement);
    };

    /**
     * ドラッグ＆ドロップで地図をスクロールする
     * @param element
     */
    const mouseDragScrollable = (element) => {
        let target;
        const elms = document.querySelectorAll(element);
        for (let i = 0; i < elms.length; i++) {
            // eslint-disable-next-line no-loop-func
            elms[i].addEventListener("mousedown", (evt) => {
                evt.preventDefault();
                target = elms[i];
                target.dataset.down = "true";
                target.dataset.move = "false";
                target.dataset.x = evt.clientX;
                target.dataset.y = evt.clientY;
                target.dataset.scrollleft = target.scrollLeft;
                target.dataset.scrolltop = target.scrollTop;
                evt.stopPropagation();
            });
            elms[i].addEventListener("click", function (evt) {
                if (elms[i].dataset !== null && elms[i].dataset.move === "true")
                    evt.stopPropagation();
            });
        }
        document.addEventListener("mousemove", function (evt) {
            if (target && target.dataset.down === "true") {
                evt.preventDefault();
                let move_x = parseInt(target.dataset.x) - evt.clientX;
                let move_y = parseInt(target.dataset.y) - evt.clientY;
                if (move_x !== 0 || move_y !== 0) {
                    target.dataset.move = "true";
                } else {
                    return;
                }
                target.scrollLeft =
                    parseInt(target.dataset.scrollleft) + move_x;
                target.scrollTop = parseInt(target.dataset.scrolltop) + move_y;
                evt.stopPropagation();
            }
        });
        document.addEventListener("mouseup", function (evt) {
            if (target && target.dataset.down === "true") {
                target.dataset.down = "false";
                evt.stopPropagation();
            }
        });
    };

    useImperativeHandle(ref, () => ({
        clearMap() {
            mapSize.targetArray = [];
            setMapSize(mapSize);
            removeAllMarkers();
        },
    }));

    const containerRef = useRef();
    const [mapWidth, setMapWidth] = useState("100%");
    const [mapSize, setMapSize] = useState({
        width: 0,
        height: 0,
        mapHeight: 0,
        zoomRate: mapScale,
        targetArray: [],
    });
    const [mapContext, setMapContext] = useState();

    useEffect(() => {
        const onResize = () => {
            setMapWidth(containerRef.current.clientWidth + "px");
        };
        onResize();
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, []);

    if (mapSize.zoomRate != mapScale) {

        let size = {
            ...mapSize,
            zoomRate: mapScale,
            mapHeight: mapSize.height * mapScale

        };
        let mapElement = document.querySelector("img#automap");
        mapElement.style.width = size.width * mapScale + "px";
        mapElement.style.height = size.height * mapScale + "px";

        setMapSize(size);
    }

    addMover(mapSize);

    return (
        <Box ref={containerRef}>
            <div id="imageContainer" style={{ width: mapWidth }}>
                <img
                    onClick={getPosition}
                    onLoad={init}
                    id="automap"
                    src={props.mapImage}
                />
            </div>
            <canvas id="mapCanvas" hidden></canvas>
            <div>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid tem xs={6}></Grid>
                    <Grid tem xs={2}>
                        <Typography
                            color="text.secondary"
                            style={{ textAlign: "right" }}
                        >
                            拡大
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Slider
                            defaultValue={0}
                            step={1}
                            min={config.map.zoom.min}
                            max={config.map.zoom.max}
                            onChange={zoomMap}
                        />
                    </Grid>
                </Grid>
            </div>
        </Box>
    );
});

export default MapView;
