const ControllerActionTypes = {
    REGIST_WEBRTC: "REGIST_WEBRTC",
    REGIST_PARTICIPANTID: "REGIST_PARTICIPANTID",
    REMOTE_CONTROL: "REMOTE_CONTROL",
    AUTO_CONTROL: "AUTO_CONTROL",
    REMOTE_EMERGENCY: "EMERGENCY",
    DATA_NOTIFY: "DATA_NOTIFY",
    MOVER_STATUS: "MOVER_STATUS",
    SET_MAPPOINTS: "SET_MAPPOINTS",
    SWITCH_CAMWRA: "SWITCH_CAMWRA",
    MOVER_LOCATION: "MOVER_LOCATION",
    OBSTACLE_SENSOR: "OBSTACLE_SENSOR",
    ALERT_CLOSE: "ALERT_CLOSE"
};

export default ControllerActionTypes;
