export const ConvertToHTMLColor = (r, g, b) => {
    r = r.toString(16);
    if (r.length === 1) r = "0" + r;

    g = g.toString(16);
    if (g.length === 1) g = "0" + g;

    b = b.toString(16);
    if (b.length === 1) b = "0" + b;

    return ("#" + r + g + b).toUpperCase();
};
