import React from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";

import config from "../../../config";

const MobileMoverInfo = ({
    hidden,
    moverStatus,
    moverNotify,
    remoteEmergency,
    pingTime,
}) => {
    let status = "オフライン";
    if (moverStatus.id !== "") {
        status = "オンライン";

        if (remoteEmergency) {
            status = "緊急停止";
        }
    }

    return (
        <Box hidden={hidden} style={{ width: "300px", padding: "10px" }}>
            <Grid container spacing={1}>
                <Grid xs={4}>
                    <Typography color="text.secondary" gutterBottom>
                        機体名
                    </Typography>
                </Grid>
                <Grid xs={8}>{config.webrtc.moverName}</Grid>

                <Grid xs={4}>
                    <Typography color="text.secondary" gutterBottom>
                        状態
                    </Typography>
                </Grid>
                <Grid xs={8}>{status}</Grid>

                <Grid xs={4}>
                    <Typography color="text.secondary" gutterBottom>
                        位置
                    </Typography>
                </Grid>
                <Grid xs={8}>
                    x: {moverNotify.x.toFixed(2)} , y:{" "}
                    {moverNotify.y.toFixed(2)}
                </Grid>

                <Grid xs={4}>
                    <Typography color="text.secondary" gutterBottom>
                        角度
                    </Typography>
                </Grid>
                <Grid xs={8}>{moverNotify.rotate.toFixed(2)}</Grid>
                {config.webrtc.ping.enable && (
                    <>
                        <Grid xs={4}>
                            <Typography color="text.secondary" gutterBottom>
                                Ping
                            </Typography>
                        </Grid>
                        <Grid xs={8}>{pingTime.toFixed(1)} ms</Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        moverStatus: state.controller.moverStatus,
        moverNotify: state.controller.moverNotify,
        remoteEmergency: state.controller.remoteEmergency,
        pingTime: state.controller.pingTime,
    };
};

export default connect(mapStateToProps)(MobileMoverInfo);
