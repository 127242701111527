import React, { useState } from "react";
import { connect } from "react-redux";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {
    ArrowUpward,
    ArrowDownward,
    ArrowForward,
    ArrowBack,
} from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Pad from "./Pad";
import config from "../../../config";

const PadController = ({
    hidden,
    moverStatus,
    isOpenDataChannel,
    remoteNotify,
    remoteEmergency,
    participantId,
    onPadDown,
    autoNotify,
    onPadUp,
}) => {
    const [speedPct, setSpeedPct] = useState(config.mover.speed.default);
    const [rotationPct, setRotationPct] = useState(
        config.mover.rotation.default
    );
    const [pushId, setPushId] = useState(0);

    const speedMarks = [
        {
            value: config.mover.speed.min,
            label: config.mover.speed.min + config.mover.speed.unit,
        },
        {
            value: config.mover.speed.max,
            label: config.mover.speed.max + config.mover.speed.unit,
        },
    ];

    const rotationMarks = [
        {
            value: config.mover.rotation.min,
            label: config.mover.rotation.min + config.mover.rotation.unit,
        },
        {
            value: config.mover.rotation.max,
            label: config.mover.rotation.max + config.mover.rotation.unit,
        },
    ];

    const onHandlePadDown = (id) => {
        if (pushId !== 0) {
            return;
        }
        setPushId(id);

        if (onPadDown) {
            const info = { x: 0, y: 0, participantId: participantId };

            if (id === 1 || id === 2) {
                info.x =
                    (speedPct / 100) *
                    (config.mover.speed.value / 100) *
                    (id === 2 ? -1 : 1);
            }
            if (id === 3 || id === 4) {
                // degree => radian
                const radian = config.mover.rotation.value * (Math.PI / 180);
                info.y = radian * (rotationPct / 100) * (id === 4 ? -1 : 1);
            }

            onPadDown(info);
        }
    };

    const onHandlePadUp = () => {
        if (pushId === 0) {
            return;
        }
        setPushId(0);

        if (onPadUp) {
            const info = { x: 0, y: 0, participantId: "" };
            onPadUp(info);
        }
    };

    const onHandlerSpeedChangeCommitted = (_, newValue) => {
        setSpeedPct(newValue);
    };

    const onHandlerRotationChangeCommitted = (_, newValue) => {
        setRotationPct(newValue);
    };

    const disabled =
        moverStatus.id === "" ||
        remoteEmergency ||
        !isOpenDataChannel ||
        participantId === "" ||
        autoNotify.drive_start ||
        (remoteNotify.participantId !== "" &&
            remoteNotify.participantId !== participantId);

    return (
        <Box
            hidden={hidden}
            style={{
                width: "300px",
                height: "260px",
                padding: "8px",
            }}
        >
            <Stack>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <Pad
                                id={1}
                                disabled={
                                    disabled || (pushId !== 1 && pushId !== 0)
                                }
                                icon={ArrowUpward}
                                onPadUp={onHandlePadUp}
                                onPadDown={onHandlePadDown}
                            />
                        </Grid>

                        <Grid item xs={4}></Grid>

                        <Grid item xs={4}>
                            <Pad
                                id={3}
                                disabled={
                                    disabled || (pushId !== 3 && pushId !== 0)
                                }
                                icon={ArrowBack}
                                onPadUp={onHandlePadUp}
                                onPadDown={onHandlePadDown}
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>

                        <Grid item xs={4}>
                            <Pad
                                id={4}
                                disabled={
                                    disabled || (pushId !== 4 && pushId !== 0)
                                }
                                icon={ArrowForward}
                                onPadUp={onHandlePadUp}
                                onPadDown={onHandlePadDown}
                            />
                        </Grid>

                        <Grid item xs={4}></Grid>

                        <Grid item xs={4}>
                            <Pad
                                id={2}
                                disabled={
                                    disabled || (pushId !== 2 && pushId !== 0)
                                }
                                icon={ArrowDownward}
                                onPadUp={onHandlePadUp}
                                onPadDown={onHandlePadDown}
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                </Box>
                <Box
                    style={{
                        marginTop: "10px",
                        width: "100%",
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            {/* <SvgIcon style={{fontSize:24}} component={ArrowUpward}/>  */}
                            <Typography color="text.secondary" gutterBottom>
                                移動
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Slider
                                disabled={disabled || pushId !== 0}
                                defaultValue={config.mover.speed.default}
                                max={config.mover.speed.max}
                                min={config.mover.speed.min}
                                valueLabelDisplay="auto"
                                onChangeCommitted={
                                    onHandlerSpeedChangeCommitted
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            {/*  <SvgIcon style={{fontSize:24}} component={Autorenew}/>     */}
                            <Typography color="text.secondary" gutterBottom>
                                回転
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Slider
                                disabled={disabled || pushId !== 0}
                                defaultValue={config.mover.rotation.default}
                                max={config.mover.rotation.max}
                                min={config.mover.rotation.min}
                                valueLabelDisplay="auto"
                                onChangeCommitted={
                                    onHandlerRotationChangeCommitted
                                }
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        moverStatus: state.controller.moverStatus,
        moverNotify: state.controller.moverNotify,
        isOpenDataChannel: state.controller.isOpenDataChannel,
        remoteNotify: state.controller.remoteNotify,
        remoteEmergency: state.controller.remoteEmergency,
        participantId: state.controller.participantId,
        autoNotify: state.controller.autoNotify,
    };
};

export default connect(mapStateToProps)(PadController);
