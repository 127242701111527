import React from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";

const AutoController = ({
    hidden,
    moverStatus,
    autoNotify,
    isOpenDataChannel,
    remoteEmergency,
    onClearMap,
    onStartDrive,
}) => {
    const disabled =
        moverStatus.id === "" || remoteEmergency || !isOpenDataChannel;

    return (
        <Box
            hidden={hidden}
            // style={{ width: "300px", height: "10px", padding: "10px" }}
        >
            <Grid container spacing={2} style={{ display: "flex" }}>
                <Grid xs={6}>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        disabled={
                            disabled ||
                            autoNotify.points.length === 0 ||
                            autoNotify.drive_start
                        }
                        fullWidth={true}
                        onClick={onClearMap}
                    >
                        クリア
                    </Button>
                </Grid>
                <Grid xs={6}>
                    <Button
                        size="large"
                        variant="contained"
                        color={autoNotify.drive_start ? "secondary" : "info"}
                        disabled={
                            disabled ||
                            (!autoNotify.drive_start &&
                                autoNotify.points.length === 0)
                        }
                        fullWidth={true}
                        onClick={onStartDrive}
                    >
                        {autoNotify.drive_start ? "中止" : "開始"}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    moverStatus: state.controller.moverStatus,
    moverNotify: state.controller.moverNotify,
    remoteEmergency: state.controller.remoteEmergency,
    isOpenDataChannel: state.controller.isOpenDataChannel,
    autoNotify: state.controller.autoNotify,
});

export default connect(mapStateToProps)(AutoController);
