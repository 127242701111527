import React from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";

import { setMapPoints } from "../action";
import store from "../../../store";
import "../style/MobileMoverRunInfo.css";

const MobileMoverRunInfo = ({ hidden, autoNotify }) => {
    const onChangeMapPointTime = (event, index) => {
        const data = autoNotify.points?.map((pointData, i) => {
            if (i === index) {
                pointData.time = Number(event.target.value);
            }

            return pointData;
        });

        store.dispatch(setMapPoints(data));
    };

    return (
        <Box hidden={hidden} className="mobileMoverStopTimeBox">
            {autoNotify.points?.map((points, index) => (
                <div key={index} className="mapPointFrame">
                    <p className="mapPointName">ポイント{index + 1}</p>
                    <div className="mapPointInputFrame">
                        <input
                            className="mapPointInput"
                            type="number"
                            min="0"
                            max="999"
                            value={String(points.time)}
                            disabled={autoNotify.drive_start}
                            onChange={(event) =>
                                onChangeMapPointTime(event, index)
                            }
                        />
                        <span className="mapPointTimeName">分間停止</span>
                    </div>
                </div>
            ))}
        </Box>
    );
};

const MobileMoverRunInfoProps = (state) => ({
    moverStatus: state.controller.moverStatus,
    autoNotify: state.controller.autoNotify,
});

export default connect(MobileMoverRunInfoProps)(MobileMoverRunInfo);
