import { createStore, combineReducers, applyMiddleware } from "redux";

import authReducer from './features/auth/reducer';
import authMiddleware from './features/auth/middleware';

import controllerReducer from './features/controller/reducer';
import controllerMiddleware from './features/controller/middleware';


const rootReducer = combineReducers({
    auth: authReducer,
    controller: controllerReducer
})


const rootMiddleWare = [authMiddleware, controllerMiddleware];

const store = createStore(rootReducer, applyMiddleware(...rootMiddleWare));


export default store;