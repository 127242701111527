import ControllerActionTypes from "./actionType";
import AuthActionTypes from "../auth/actionType";

const controllerMiddleWare = (store) => (next) => (action) => {
    const { getState } = store;

    // eslint-disable-next-line default-case
    switch (action.type) {
        case ControllerActionTypes.REMOTE_CONTROL:
            if (
                !getState().controller.webrtcObj ||
                getState().controller.moverStatus.id === "" ||
                !getState().controller.isOpenDataChannel ||
                getState().controller.remoteEmergency
            ) {
                break;
            }

            const notify = {
                remoteNotify: action.remoteControl,
                type: action.type,
                ping: true,
                name: "data",
            };

            //Use data channels to send remote operations
            getState().controller.webrtcObj.executeCommand(
                "sendEndpointTextMessage",
                "",
                JSON.stringify(notify)
            );
            break;

        case ControllerActionTypes.AUTO_CONTROL:
            if (
                !getState().controller.webrtcObj ||
                getState().controller.moverStatus.id === "" ||
                !getState().controller.isOpenDataChannel ||
                getState().controller.remoteEmergency
            ) {
                break;
            }

            const autoNotify = {
                autoNotify: {
                    points: action.autoControl
                        ? getState().controller.autoNotify.points
                        : [],
                    drive_start: action.autoControl,
                },
            };

            //Use data channels to send auto operations
            getState().controller.webrtcObj.executeCommand(
                "sendEndpointTextMessage",
                "",
                JSON.stringify(autoNotify)
            );
            break;

        case ControllerActionTypes.REMOTE_EMERGENCY:
            if (
                !getState().controller.webrtcObj ||
                getState().controller.moverStatus.id === "" ||
                !getState().controller.isOpenDataChannel
            ) {
                break;
            }

            let emergency = {
                remoteNotify: { x: 0, y: 0, participantId: "" },
                remoteEmergency: action.remoteEmergency,
            };

            if (getState().controller.autoNotify.drive_start) {
                emergency = {
                    ...emergency,
                    autoNotify: {
                        points: [],
                        drive_start: false,
                    },
                };
            }

            getState().controller.webrtcObj.executeCommand(
                "sendEndpointTextMessage",
                "",
                JSON.stringify(emergency)
            );

            break;
        case ControllerActionTypes.SWITCH_CAMWRA:
            let swtichCameraNotify = {
                swtichCamera: true,
            };

            getState().controller.webrtcObj.executeCommand(
                "sendEndpointTextMessage",
                "",
                JSON.stringify(swtichCameraNotify)
            );
            break;

        case AuthActionTypes.SIGNOUT:
            if (
                !getState().controller.webrtcObj ||
                !getState().controller.moverStatus
            ) {
                break;
            }

            getState().controller.webrtcObj.executeCommand("hangup");

            break;

        case ControllerActionTypes.DATA_NOTIFY:
            if (
                !getState().controller.webrtcObj ||
                getState().controller.moverStatus.id === "" ||
                !getState().controller.isOpenDataChannel ||
                getState().controller.remoteEmergency
            ) {
                break;
            }

            // 自律走行がゴールないし、中止になったとき
            if ((action.notify.autoStatus && getState().controller.autoNotify.drive_start) &&
                (action.notify.autoStatus.status !== "goal" ||
                    (action.notify.autoStatus.points === action.notify.autoStatus.checkpoint &&
                        action.notify.autoStatus.status === "goal"))) {
                const autoNotify = {
                    autoNotify: {
                        points: action.autoControl
                            ? getState().controller.autoNotify.points
                            : [],
                        drive_start: false,
                    },
                };

                //Use data channels to send auto operations
                getState().controller.webrtcObj.executeCommand(
                    "sendEndpointTextMessage",
                    "",
                    JSON.stringify(autoNotify)
                );
            }
    }

    return next(action);
};

export default controllerMiddleWare;
