import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Header from "./components/Header";
import MobileMoverInfo from "./components/MobileMoverInfo";
import PadController from "./components/PadController";
import AutoController from "./components/AutoController";
import MobileMoverRunInfo from "./components/MobileMoverRunInfo";
import ObstacleInfo from "./components/ObstacleInfo";
import WebRTCView from "./components/WebRTCView";
import MapView from "./components/MapView";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import {
    remoteControl,
    autoControl,
    remoteEmergency,
    setMapPoints,
    alertClose
} from "./action";
import { redirectLogin } from "../auth/action";
import store from "../../store";
import config from "../../config";

import "./style/style.css";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Controller = ({
    isAuthentication,
    moverStatus,
    isOpenDataChannel,
    emergency,
    autoNotify,
    moverNotify,
    autoStatus,
}) => {
    const [value, setValue] = React.useState(0);
    const [aircraftInfoValue, setAircraftInfoValue] = React.useState(0);
    const [remote, setRemote] = React.useState({ interval: 0, pad: {} });
    const mapRef = useRef();
    const [mapConfig, setMapConfig] = React.useState(
        {
            moverWidth: 12,
            mapScale: 3.0,
            enablePinColor: "#FFFFFF",
            yaml: {
                resolution: 0.05,
                origin: [-25, -25, 0]
            },
            mapImage: "map.png",
        });

    useEffect(() => {
        // Publicフォルダ内のJSONファイルのパスを指定
        fetch('/mapconfig.json')
            .then(response => response.json())
            .then(data => setMapConfig(data))
            .catch(error => console.error("unload mapconfig.json", error));
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const aircraftInfoHandleChange = (event, newValue) => {
        setAircraftInfoValue(newValue);
    };

    const onHandlePadDown = (pad) => {
        remote.interval = setInterval(onSendRemoteControl, 100);
        remote.pad = pad;
        setRemote(remote);
    };

    const onHandlePadUp = (pad) => {
        clearInterval(remote.interval);

        remote.interval = 0;
        remote.pad = pad;
        setRemote(remote);

        onSendRemoteControl();
    };

    const onSendRemoteControl = () => {
        if (emergency || autoNotify.drive_start) {
            if (remote.interval) {
                clearInterval(remote.interval);

                remote.interval = 0;
                remote.pad = { x: 0, y: 0, participantId: "" };
                setRemote(remote);
            }

            return;
        }

        store.dispatch(remoteControl(remote.pad));
    };

    const onHandleEmergencyClick = (event) => {
        store.dispatch(remoteEmergency(!emergency));
    };

    const onHandleClearMap = (event) => {
        mapRef.current.clearMap();
        store.dispatch(setMapPoints([]));
    };

    const onHandleStartDrive = (event) => {
        store.dispatch(autoControl(!autoNotify.drive_start));
    };

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        store.dispatch(alertClose());
    };


    if (config.auth.enabled) {
        // If you are not authenticated, proceed to the login screen
        if (!isAuthentication) {
            store.dispatch(redirectLogin());
        }
    }

    const disabled = moverStatus.id === "" || !isOpenDataChannel;

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Header />
            <Snackbar open={autoStatus.open}
                autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleAlertClose} severity={autoStatus.severity} style={{ width: '300px' }}>
                    {autoStatus.msg}
                </Alert>
            </Snackbar>
            <Stack direction="row" style={{ padding: 10 }}>
                <Box className="noselect">
                    <Stack>
                        <Card>
                            <CardContent>
                                <Tabs
                                    value={aircraftInfoValue}
                                    onChange={aircraftInfoHandleChange}
                                >
                                    <Tab
                                        style={{ flexGrow: 1, zIndex: 200 }}
                                        label="機体情報"
                                    />
                                    <Tab
                                        style={{ flexGrow: 1, zIndex: 200 }}
                                        label="障害物センサー"
                                    />
                                </Tabs>
                                <MobileMoverInfo
                                    hidden={aircraftInfoValue !== 0}
                                />
                                <ObstacleInfo
                                    hidden={aircraftInfoValue !== 1}
                                    mapImage={"automap.png"}
                                />
                            </CardContent>
                        </Card>
                        <Card style={{ marginTop: 10 }}>
                            <CardContent>
                                <Tabs value={value} onChange={handleChange}>
                                    <Tab
                                        style={{ flexGrow: 1, zIndex: 200 }}
                                        label="遠隔操作"
                                    />
                                    <Tab
                                        style={{ flexGrow: 1, zIndex: 200 }}
                                        label="自立走行"
                                    />
                                </Tabs>
                                <PadController
                                    hidden={value !== 0}
                                    onPadDown={onHandlePadDown}
                                    onPadUp={onHandlePadUp}
                                />
                                <MobileMoverRunInfo hidden={value !== 1} />
                                <AutoController
                                    hidden={value !== 1}
                                    onClearMap={onHandleClearMap}
                                    onStartDrive={onHandleStartDrive}
                                />
                            </CardContent>
                            <CardActions>
                                <Button
                                    disabled={disabled}
                                    size="large"
                                    variant="contained"
                                    color={emergency ? "info" : "error"}
                                    fullWidth={true}
                                    onClick={onHandleEmergencyClick}
                                >
                                    {emergency ? "緊急停止の解除" : "緊急停止"}
                                </Button>
                            </CardActions>
                        </Card>
                    </Stack>
                </Box>
                <Box style={{ width: "100%", marginLeft: "10px" }}>
                    <Card className="noselect">
                        <CardContent>
                            <WebRTCView />
                        </CardContent>
                    </Card>
                    <Card style={{ marginTop: "10px" }} className="noselect">
                        <CardContent>
                            <MapView
                                ref={mapRef}
                                moverPosition={moverNotify}
                                moverWidth={mapConfig.moverWidth}
                                mapScale={mapConfig.mapScale}
                                enablePinColor={mapConfig.enablePinColor}
                                yaml={mapConfig.yaml}
                                disabled={autoNotify.drive_start || disabled}
                                mapImage={"map.png"}
                            />
                        </CardContent>
                    </Card>
                </Box>
            </Stack>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        moverStatus: state.controller.moverStatus,
        emergency: state.controller.remoteEmergency,
        isAuthentication: state.auth.token !== "",
        isOpenDataChannel: state.controller.isOpenDataChannel,
        autoNotify: state.controller.autoNotify,
        moverNotify: state.controller.moverNotify,
        autoStatus: state.controller.autoStatus,
    };
};

export default connect(mapStateToProps)(Controller);
